<script>
import CreateEditView from '@/mixins/create-edit-view';
import LabeledInput from '@/components/form/LabeledInput';
import CruResource from '@/components/CruResource';
import { _CREATE, _EDIT, _VIEW } from '@/config/query-params';

export default {
  components: {
    CruResource,
    LabeledInput,
  },

  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: _EDIT
    },
    realMode: {
      type:    String,
      default: _EDIT
    }
  },
  computed: {
    _CREATE() {
      return _CREATE;
    },
    _EDIT() {
      return _EDIT;
    },
    _VIEW() {
      return _VIEW;
    }
  },
  methods: {
    done() {
      this.$router.go(-1);
    }
  },
  created() {
    // Add fields missing when creating new
    const obj = {
      issuer: '', user: '', limits: { cpu: '', memory: '' }
    };

    if (!this.value.spec) {
      this.$set(this.value, 'spec', obj);
    }
  }

};
</script>

<template>
  <CruResource
    :cancel-event="true"
    :mode="_EDIT"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    @error="e=>errors = e"
    @finish="save"
    @cancel="done"
  >
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.metadata.name"
        type="text"
        :required="true"
        :label="t('catalog.install.steps.values.license.name')"
        :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.license.name')"
        :mode="(realMode===_EDIT||realMode===_VIEW) ? _VIEW : _EDIT"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.issuer"
        type="text"
        :required="true"
        :label="t('catalog.install.steps.values.license.issuer')"
        :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.license.issuer')"
        mode="edit"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.user"
        type="text"
        :required="true"
        :label="t('catalog.install.steps.values.license.user')"
        :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.license.user')"
        mode="edit"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.limits.cpu"
        type="text"
        :required="true"
        :label="t('catalog.install.steps.values.license.cpu')"
        :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.license.cpu')"
        mode="edit"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.limits.memory"
        type="text"
        :required="true"
        :label="t('catalog.install.steps.values.license.memory')"
        :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.license.memory')"
        mode="edit"
      />
    </div>
  </CruResource>
</template>
