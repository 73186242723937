<script>
import LabelValue from '@/components/LabelValueRow';
import day from 'dayjs';

export default {
  name:       'DetailLicense',
  components: { LabelValue },
  async fetch() {

  },

  props: {

    value: {
      type:    Object,
      default: () => {
        return {};
      }
    }

  },
  computed: {
    creationTimestamp() {
      return day(this.value?.metadata?.creationTimestamp).format('YYYY-MM-DD HH:mm:ss');
    },
  }
};
</script>

<template>
  <div class="host-detail">
    <h3>{{ t('catalog.install.steps.values.defaultInfo') }}</h3>
    <div class="row  mb-20">
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.license.name')" :value="value.metadata.name" />
      </div>
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.license.issuer')" :value="value.spec.issuer" />
      </div>
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.license.user')" :value="value.spec.user" />
      </div>
    </div>
    <div class="row  mb-20">
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.license.cpu')" :value="value.spec.limits.cpu" />
      </div>
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.license.memory')" :value="value.spec.limits.memory" />
      </div>
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.license.creationTimestamp')" :value="creationTimestamp" />
      </div>
    </div>
    <h3>{{ t('catalog.install.steps.values.license.currentState') }}</h3>
    <div class="row mb-20">
      <div v-if="value.status && value.status.valid" class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.license.whetherUse')" :value="t('catalog.install.steps.values.license.available')" />
      </div>
      <div v-else class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.license.whetherUse')" :value="t('catalog.install.steps.values.license.notAvailable')" />
      </div>
      <div class="col span-3">
        <LabelValue name="CPU" :value="value.status && value.status.used.cpu" />
      </div>
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.license.ram')" :value="value.status && value.status.used.memory" />
      </div>
    </div>
  </div>
</template>
