<script>
import ResourceTable from '@/components/ResourceTable';

export default {
  components: { ResourceTable },
  mounted() {
    this.host = window.location.host;
  },
  data() {
    return { host: '' };
  },
  computed:   {
    headers() {
      const urls = [];

      this.$attrs.rows.forEach((v) => {
        urls.push({
          name: v.metadata.name,
          url:  `https://${ this.host }/${ v.spec.cata }/${ v.metadata.name }/`,
        });
      });
      const headers = [
        {
          name:          'state',
          labelKey:      'tableHeaders.state',
          value:         'stateDisplay',
          width:     100,
          default:   'unknown',
          formatter: 'BadgeStateFormatter',
          sort:      ['stateSort', 'nameSort'],
        },
        {
          name:          'name',
          labelKey:      'tableHeaders.name',
          value:         'nameDisplay',
          formatter:     'PiLink',
          formatterOpts: { url: urls },
          sort:          ['nameSort'],
        },
        {
          name:      'catalog',
          label:     this.t('catalog.install.steps.values.paiingress.cata'),
          sort:      ['.spec.cata'],
          value: '$.spec.cata',
        },
        {
          name:      'port',
          label:     this.t('catalog.install.steps.values.paiingress.port'),
          sort:      ['.spec.port'],
          value: '$.spec.port',
        },
        {
          name:      'message',
          label:     'Message',
          sort:      ['.status.msg'],
          value: '$.status.msg',
        },
      ];

      return headers;
    }
  }
};
</script>

<template>
  <ResourceTable :schema="$attrs.schema" :rows="$attrs.rows" :headers="headers" :overflow-y="true" />
</template>
