<script>
import CreateEditView from '@/mixins/create-edit-view';
import LabeledInput from '@/components/form/LabeledInput';
import CruResource from '@/components/CruResource';
import LabeledSelect from '@/components/form/LabeledSelect';
import ArrayList from '@/components/form/ArrayList';

export default {
  components: {
    CruResource,
    LabeledInput,
    LabeledSelect,
    ArrayList,
  },

  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'edit'
    },
    realMode: {
      type:    String,
      default: 'edit'
    }
  },
  data() {
    this.$set(this.value.metadata, 'namespace', 'picloud-system');
    if (this.realMode === 'create') {
      this.$set(this.value.metadata, 'name', '');
      this.$set(this.value, 'spec', { cata: '', backends: [] });
    }

    return { backends: this.value.spec.backends.join(';') };
  },
  methods: {
    done() {
      this.$router.go(-1);
    },
    onInput(e) {
      const arr = e.replace(' ', '').split(';');

      this.backends = arr.join(';');
      this.$set(this.value.spec, 'backends', arr);
    },
  },
};
</script>

<template>
  <CruResource
    :cancel-event="true"
    :mode="mode"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    @error="e=>errors = e"
    @finish="save"
    @cancel="done"
  >
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.metadata.name"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.paiingress.name')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.paiingress.name')"
          :mode="mode"
        />
      </div>
      <div class="col span-6">
        <LabeledSelect
          v-model.trim="value.spec.cata"
          :required="true"
          :label="t('catalog.install.steps.values.paiingress.cata')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.paiingress.cata')"
          :options="[
            { label: 'qvm', value: 'qvm' },
            { label: 'portal', value: 'portal' },
          ]"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.number="value.spec.port"
          type="number"
          :required="true"
          :label="t('catalog.install.steps.values.paiingress.port')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.paiingress.port')"
          :mode="mode"
        />
      </div>
    </div>
    <ArrayList
      v-model.trim="value.spec.backends"
      :title="t('catalog.install.steps.values.paiingress.backends')"
      :protip="t('catalog.install.steps.values.paiingress.tooltip')"
      :show-header="false"
      :add-label="t('catalog.install.steps.values.paiingress.add')"
      :mode="mode"
      :value-placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.paiingress.backends')"
    />
  </CruResource>
</template>
