<script>
import CreateEditView from '@/mixins/create-edit-view';
import LabeledInput from '@/components/form/LabeledInput';
import LabeledSelect from '@/components/form/LabeledSelect';
import LabeledRadioGroup from '@/components/form/LabeledRadioGroup';
import KeyValue from '@/components/form/KeyValue';
import Tooltip from '@/components/Tooltip';

export default {
  components: {
    LabeledInput,
    LabeledSelect,
    LabeledRadioGroup,
    KeyValue,
    Tooltip,
  },
  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      },
    },
  },
  mode: {
    type:    String,
    default: 'edit',
  },
  methods: {
    changGrafanaHost(e) {
      this.$set(this.value.grafana.ingress.hosts, '0', e);
    },
    changGrafanaUrl(e) {
      this.$set(this.value.grafana.datasources['datasources.yaml'].datasources[0], 'url', e);
    }
  },
};
</script>

<template>
  <div>
    <h2>{{ t('catalog.install.steps.values.Edit') }}</h2>
    <div>
      <h4 style="display: inline-block">
        TAGS
      </h4>
      <Tooltip :value="t('catalog.install.steps.values.qvm.subTootip')" :status="null" />
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledRadioGroup
          v-model.trim="value.tags.registry"
          name="debug"
          label="registry"
          :options="[
            { label: t('catalog.install.steps.values.trueOne'), value: true },
            { label: t('catalog.install.steps.values.falseOne'), value: false },
          ]"
          :default-value="value.tags.registry"
          :row="true"
          :required="true"
        />
      </div>
    </div>
    <hr class="mt-20 mb-20" />
    <h4>REGISTRY</h4>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.registry.storageSize"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.storageSize')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.storageSize')"
          mode="edit"
        />
      </div>
    </div>
    <h5>StatefulSet</h5>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.registry.statefulSet.registry.image"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.warehouseImage')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.warehouseImage')"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.registry.statefulSet.ui.image"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.UIImage')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.UIImage')"
          mode="edit"
        />
      </div>
    </div>
    <hr class="mt-20 mb-20" />
    <h4>QVM</h4>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.ingress.name"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.registryName')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.registryName')"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.ingress.host"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.host')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.host')"
          mode="edit"
          @input="changGrafanaHost"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.deployment.name"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.deploymentName')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.deploymentName')"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.deployment.imagePullPolicy"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.imagePullPolicy')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.imagePullPolicy')"
          mode="edit"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledSelect
          v-model.trim="value.deployment.netMode"
          :mode="mode"
          :options="['802.1q', '802.1ad']"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.netMode')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.netMode')"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.picloudurl"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.picloudurl')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.picloudurl')"
          mode="edit"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.adminUser"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.adminUser')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.adminUser')"
          mode="edit"
        />
      </div>
    </div>
    <h5>镜像</h5>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.deployment.images.imageBuilder"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.imageBuilder')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.imageBuilder')"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.deployment.images.vmRunner"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.vmRunner')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.vmRunner')"
          mode="edit"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.deployment.images.qvm"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.qvm')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.qvm')"
          mode="edit"
        />
      </div>
    </div>
    <h5>URL</h5>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.deployment.url.ipvs"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.ipvs')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.ipvs')"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.deployment.url.prometheusServer"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.prometheusServer')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.prometheusServer')"
          mode="edit"
          @input="changGrafanaUrl"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.deployment.url.prometheusAlertmanager"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.prometheusAlertmanager')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.prometheusAlertmanager')"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.deployment.url.grafanaAPI"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.qvm.grafanaAPI')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.qvm.grafanaAPI')"
          mode="edit"
        />
      </div>
    </div>
  </div>
</template>
