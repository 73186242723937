import { render, staticRenderFns } from "./node.vue?vue&type=template&id=0216c24f&scoped=true&"
import script from "./node.vue?vue&type=script&lang=js&"
export * from "./node.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../src/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0216c24f",
  null
  
)

export default component.exports