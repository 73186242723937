<script>
export default {
  props: {
    value: {
      type:    [String, Object],
      default: null
    },

    status: {
      type:    String,
      default: 'error'
    },

    hover: {
      type:    Boolean,
      default: true
    }
  },
};
</script>

<template>
  <div ref="container" class="labeled-tooltip" :class="{[status]: true, hoverable: hover}">
    <template v-if="hover">
      <i v-tooltip="value.content ? { ...{content: value.content, classes: [`tooltip-${status}`]}, ...value } : value" :class="{'hover':!value}" class="icon icon-info" />
    </template>
    <template v-else>
      <i :class="{'hover':!value}" class="icon icon-info" />
      <div v-if="value" class="tooltip" x-placement="bottom">
        <div class="tooltip-arrow" />
        <div class="tooltip-inner">
          {{ value }}
        </div>
      </div>
    </template>
  </div>
</template>

<style lang='scss' scoped>
.labeled-tooltip {
  position: relative;
  display: inline-block;
  width: 0;
  margin-left: 5px;

  &.hoverable {
    height: 0;
  }

  .status-icon {
    position:  relative;
    font-size: 20px;
    z-index: z-index(hoverOverContent);
  }

  .tooltip {
    position: relative;

    .tooltip-arrow {
      right: 30px;
    }

    .tooltip-inner {
      padding: 10px;
    }
  }

  @mixin tooltipColors($color) {
    .status-icon {
      color: $color;
    }
    .tooltip {
      .tooltip-inner {
        color: var(--input-bg);
        background: $color;
        border-color: $color;
      }

      .tooltip-arrow {
        border-bottom-color: $color;
        &:after {
          border: none;
        }
      }
    }
  }

  &.error {
    @include tooltipColors(var(--error));
  }

  &.warning {
    @include tooltipColors(var(--warning));
  }

  &.success {
    @include tooltipColors(var(--success));
  }
}

// Ensure code blocks inside tootips don't look awful
.tooltip {
  .tooltip-inner {
    > pre {
      padding: 2px;
      vertical-align: middle;
    }
  }
}
</style>
