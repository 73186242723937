import { render, staticRenderFns } from "./configmap.vue?vue&type=template&id=51504a16&scoped=true&"
import script from "./configmap.vue?vue&type=script&lang=js&"
export * from "./configmap.vue?vue&type=script&lang=js&"
import style0 from "./configmap.vue?vue&type=style&index=0&id=51504a16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../src/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51504a16",
  null
  
)

export default component.exports