<script>
import CreateEditView from '@/mixins/create-edit-view';
import CruResource from '@/components/CruResource';
import LabelValue from '@/components/LabelValue';

export default {
  components: {
    CruResource,
    LabelValue,
  },

  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'edit'
    },
  },
  methods: {
    done() {
      this.$router.go(-1);
    },
  },
};
</script>

<template>
  <CruResource
    :cancel-event="true"
    :mode="mode"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    @error="e=>errors = e"
    @finish="save"
    @cancel="done"
  >
    <h3>详细信息</h3>
    <div class="row mb-20">
      <div class="col span-6">
        <LabelValue name="πCloudURL" :value="value.spec.picloudurl" />
      </div>
      <div class="col span-6">
        <LabelValue name="QVMURL" :value="value.spec.qvmurl" />
      </div>
    </div>
  </CruResource>
</template>
