<script>
import CreateEditView from '@/mixins/create-edit-view';
import LabeledInput from '@/components/form/LabeledInput';
import LabeledRadioGroup from '@/components/form/LabeledRadioGroup';

export default {
  components: { LabeledInput, LabeledRadioGroup },
  mixins:     [CreateEditView],
  props:      {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      },
    },
  },
  mode: {
    type:    String,
    default: 'edit',
  },
};
</script>

<template>
  <div>
    <h2>{{ t("catalog.install.steps.values.Edit") }}</h2>
    <h4>NATS</h4>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.nats.image"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.nats.image')"
          :placeholder="
            t('catalog.install.steps.values.placeholder') +
              t('catalog.install.steps.values.nats.image')
          "
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.nats.pullPolicy"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.nats.pullPolicy')"
          :placeholder="
            t('catalog.install.steps.values.placeholder') +
              t('catalog.install.steps.values.nats.pullPolicy')
          "
          mode="edit"
        />
      </div>
    </div>
    <h5>{{ t("catalog.install.steps.values.nats.logging") }}</h5>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledRadioGroup
          v-model.trim="value.nats.logging.debug"
          name="debug"
          :label="t('catalog.install.steps.values.nats.debug')"
          :options="[
            { label: 'true', value: true },
            { label: 'false', value: false },
          ]"
          :default-value="value.nats.logging.debug"
          :row="true"
          :required="true"
        />
      </div>
      <div class="col span-6">
        <LabeledRadioGroup
          v-model.trim="value.nats.logging.trace"
          name="trace"
          :label="t('catalog.install.steps.values.nats.trace')"
          :options="[
            { label: 'true', value: true },
            { label: 'false', value: false },
          ]"
          :default-value="value.nats.logging.trace"
          :row="true"
          :required="true"
        />
      </div>
    </div>
    <h4>{{ t("catalog.install.steps.values.nats.cluster") }}</h4>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledRadioGroup
          v-model.trim="value.cluster.enabled"
          name="enabled"
          :label="t('catalog.install.steps.values.nats.enabled')"
          :options="[
            { label: 'true', value: true },
            { label: 'false', value: false },
          ]"
          :default-value="value.cluster.enabled"
          :row="true"
          :required="true"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.cluster.replicas"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.nats.replicas')"
          :placeholder="
            t('catalog.install.steps.values.placeholder') +
              t('catalog.install.steps.values.nats.replicas')
          "
          mode="edit"
        />
      </div>
    </div>
    <h4>{{ t("catalog.install.steps.values.nats.leafnodes") }}</h4>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledRadioGroup
          v-model.trim="value.leafnodes.enabled"
          name="enabled"
          :label="t('catalog.install.steps.values.nats.leafnodesEnabled')"
          :options="[
            { label: 'true', value: true },
            { label: 'false', value: false },
          ]"
          :default-value="value.leafnodes.enabled"
          :row="true"
          :required="true"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.leafnodes.port"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.nats.port')"
          :placeholder="
            t('catalog.install.steps.values.placeholder') +
              t('catalog.install.steps.values.nats.port')
          "
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.leafnodes.noAdvertise"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.nats.noAdvertise')"
          :placeholder="
            t('catalog.install.steps.values.placeholder') +
              t('catalog.install.steps.values.nats.noAdvertise')
          "
          mode="edit"
        />
      </div>
    </div>
    <h4>{{ t("catalog.install.steps.values.nats.gateway") }}</h4>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledRadioGroup
          v-model.trim="value.gateway.enabled"
          name="enabled"
          :label="t('catalog.install.steps.values.nats.gatewayEnabled')"
          :options="[
            { label: 'true', value: true },
            { label: 'false', value: false },
          ]"
          :default-value="value.gateway.enabled"
          :row="true"
          :required="true"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.gateway.name"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.nats.name')"
          :placeholder="
            t('catalog.install.steps.values.placeholder') +
              t('catalog.install.steps.values.nats.name')
          "
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.gateway.port"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.nats.gatewayPort')"
          :placeholder="
            t('catalog.install.steps.values.placeholder') +
              t('catalog.install.steps.values.nats.gatewayPort')
          "
          mode="edit"
        />
      </div>
    </div>
  </div>
</template>

<style>
</style>
