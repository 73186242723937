<script>
export default {
  name: 'Message',
  data() {
    return {
      visible:  false,
      type:     'info',
      message:  '',
      duration: 3000,
      timer:    null,
      types:    {
        warning:     '#FAAD14',
        success: '#52C41A',
        info:     '#52C41A',
        error:     '#FF4D4F',
      },
    };
  },
  mounted() {
    this.setTimer(); // 组件挂载的时候开始计时，计时完成移除
  },
  methods: {
    setTimer() { // 组件停留时间duration
      this.timer = setTimeout(() => {
        this.close();
      }, this.duration);
    },
    close() {
      this.visible = false;
      clearTimeout(this.timer);
    },
    clearTimer() { // 鼠标移入提醒框时，清除定时器，提醒框持久化显示
      // console.log('清除定时器，不会自动消失');
      clearTimeout(this.timer);
    },
    startTimer() { // 鼠标离开提醒框，定时器开始计时
      // console.log('开始定时器');
      this.setTimer();
    },
    handleAfterLeave() { // 移除动画执行完成后，销毁组件，并将其从父节点移除
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    }
  }
};
</script>
<template>
  <transition name="fade" @after-leave="handleAfterLeave">
    <div
      v-show="visible"
      class="message"
      @mouseleave="startTimer"
      @mouseenter="clearTimer"
    >
      <div class="icon">
        <svg
          t="1653304479281"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1034"
          width="16"
          height="16"
          style="position:relative; top:3px;"
        >
          <path d="M512 512m-448 0a448 448 0 1 0 896 0 448 448 0 1 0-896 0Z" :fill="types[type]" p-id="1035"></path>
          <path d="M469.333333 469.333333h85.333334v234.666667h-85.333334z" fill="#ffffff" p-id="1036"></path>
          <path
            d="M512 352m-53.333333 0a53.333333 53.333333 0 1 0 106.666666 0 53.333333 53.333333 0 1 0-106.666666 0Z"
            fill="#ffffff"
            p-id="1037"
          ></path>
        </svg>
      </div>
      {{ message }}
    </div>
  </transition>
</template>
<style scoped lang="scss">
.fade-enter-active {
  animation: fade-in 0.6s ease;
}
.fade-leave-active {
  animation: fade-out 0.6s ease;
}
@keyframes fade-in {
  0% {
    top: -40px;
    opacity: 0;
  }
  100% {
    top: 20px;
    opacity: 100%;
  }
}
@keyframes fade-out {
  0% {
    top: 20px;
    opacity: 100%;
  }
  100% {
    top: -40px;
    opacity: 0;
  }
}
.message {
  position: absolute;
  top: 20px;
  left: 50%;
  z-index: 999;
  min-width: 200px;
  transform: translateX(-50%);
  line-height: 25px;
  padding: 10px 16px;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  background: #fff;
  pointer-events: all;
  color: #000000d9;
}
.icon{
  margin-top: 2px;
}
</style>
