<script>
import LabelValue from '@/components/LabelValueRow';
import ResourceTabs from '@/components/form/ResourceTabs';
import Tab from '@/components/Tabbed/Tab';
import SortableTable from '@/components/SortableTable';
import day from 'dayjs';

export default {
  name:       'DetailMachine',
  components: {
    LabelValue, ResourceTabs, Tab, SortableTable
  },
  props: {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
  },
  computed: {
    creationTimestamp() {
      return day(this.value?.metadata?.creationTimestamp).format('YYYY-MM-DD HH:mm:ss');
    },
    headers() {
      const diskHeaders = ['name', 'size', 'type'];
      const networkHeaders = ['ifname', 'address', 'mtu', 'operstate'];
      const disks = diskHeaders.map((v) => {
        return {
          label:   this.t(`catalog.install.steps.values.machine.${ v }`),
          name:    v,
          sort:    [v],
          value:   `$.${ v }`,
        };
      });
      const links = networkHeaders.map((v) => {
        return {
          label:   this.t(`catalog.install.steps.values.machine.${ v }`),
          name:    v,
          sort:    [v],
          value:   `$.${ v }`,
        };
      });

      return { disks, links };
    },
  }
};
</script>

<template>
  <div class="host-detail">
    <h3>{{ t('catalog.install.steps.values.defaultInfo') }}</h3>
    <div class="row mb-20">
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.machine.name')" :value="value.metadata.name" />
      </div>

      <div class="col span-3">
        <LabelValue name="IP" :value="value.spec.ip" />
      </div>

      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.machine.operatingSystem')" :value="value.spec.os" />
      </div>
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.machine.creationDate')" :value="creationTimestamp" />
      </div>
    </div>
    <div class="row mb-20">
      <div class="col span-12">
        <ResourceTabs mode="view" :need-related="false">
          <Tab :label="t('catalog.install.steps.values.machine.disk')" name="bundles" :weight="30">
            <SortableTable
              :rows="value.spec.disks ? value.spec.disks : []"
              :schema="[]"
              :headers="headers.disks"
              :row-actions="false"
              :table-actions="false"
            />
          </Tab>
          <Tab :label="t('catalog.install.steps.values.machine.network')" name="resources" :weight="20">
            <SortableTable
              :rows="value.spec.links ? value.spec.links : []"
              :schema="[]"
              :headers="headers.links"
              :row-actions="false"
              :table-actions="false"
            />
          </Tab>
        </ResourceTabs>
        <div />
      </div>
    </div>
  </div>
</template>
