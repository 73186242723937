<script>
import CreateEditView from '@/mixins/create-edit-view';
import LabeledInput from '@/components/form/LabeledInput';
import LabeledRadioGroup from '@/components/form/LabeledRadioGroup';

export default {
  components: { LabeledRadioGroup, LabeledInput },
  mixins:     [CreateEditView],
  props:      {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    }
  },
  mode: {
    type:    String,
    default: 'edit'
  },
  methods: {
    onValueChange(e) {
      this.$set(this.value.ingress, 'hosts', [e]);
      this.$set(this.value.ingress, 'tls', [e]);
    }
  },
};
</script>
<template>
  <div>
    <h2>{{ t('catalog.install.steps.values.Edit') }}</h2>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.image.pullPolicy"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.nifi.pullPolicy')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.nifi.pullPolicy')"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.image.repository"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.nifi.repository')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.nifi.repository')"
          mode="edit"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.image.tag"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.nifi.tag')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.nifi.tag')"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.properties.sensitiveKey"
          type="password"
          :required="true"
          :label="t('catalog.install.steps.values.nifi.sensitiveKey')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.nifi.sensitiveKey')"
          mode="edit"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.auth.singleUser.username"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.nifi.username')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.nifi.username')"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.auth.singleUser.password"
          type="password"
          :required="true"
          :label="t('catalog.install.steps.values.nifi.password')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.nifi.password')"
          mode="edit"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledRadioGroup
          v-model.trim="value.ingress.enabled"
          name="enabled"
          :label="t('catalog.install.steps.values.nifi.enabled')"
          :options="[{label:t('catalog.install.steps.values.yes'),value:true}, {label:t('catalog.install.steps.values.no'),value:false}]"
          :default-value="value.ingress.enabled"
          :required="true"
          :row="true"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.ingress.hosts"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.nifi.hosts')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.nifi.hosts')"
          mode="edit"
          @input="onValueChange"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledRadioGroup
          v-model.trim="value.auth.oidc.enabled"
          name="oidcEnabled"
          :label="t('catalog.install.steps.values.nifi.oidcEnabled')"
          :options="[{label:t('catalog.install.steps.values.yes'),value:true}, {label:t('catalog.install.steps.values.no'),value:false}]"
          :default-value="value.auth.oidc.enabled"
          :required="true"
          :row="true"
        />
      </div>
    </div>
    <div v-if="value.auth.oidc.enabled">
      <div class="detail mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.auth.oidc.discoveryUrl"
            type="text"
            :required="true"
            :label="t('catalog.install.steps.values.nifi.discoveryUrl')"
            :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.nifi.discoveryUrl')"
            mode="edit"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.auth.oidc.claimIdentifyingUser"
            type="text"
            :required="true"
            :label="t('catalog.install.steps.values.nifi.claimIdentifyingUser')"
            :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.nifi.claimIdentifyingUser')"
            mode="edit"
          />
        </div>
      </div>
      <div class="detail mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.auth.oidc.clientId"
            type="text"
            :required="true"
            :label="t('catalog.install.steps.values.nifi.clientId')"
            :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.nifi.clientId')"
            mode="edit"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.auth.oidc.clientSecret"
            type="text"
            :required="true"
            :label="t('catalog.install.steps.values.nifi.clientSecret')"
            :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.nifi.clientSecret')"
            mode="edit"
          />
        </div>
      </div>
      <div class="detail mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.auth.oidc.admin"
            type="text"
            :required="true"
            :label="t('catalog.install.steps.values.nifi.admin')"
            :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.nifi.admin')"
            mode="edit"
          />
        </div>
      </div>
    </div>
  </div>
</template>
