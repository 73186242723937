<script>
import CreateEditView from '@/mixins/create-edit-view';
import LabeledInput from '@/components/form/LabeledInput';
import LabeledSelect from '@/components/form/LabeledSelect';
import CruResource from '@/components/CruResource';
import LabeledRadioGroup from '@/components/form/LabeledRadioGroup';
import KeyValue from '@/components/form/KeyValue';
import { _CLONE, _CREATE, _EDIT } from '@/config/query-params';

export default {
  components: {
    LabeledRadioGroup,
    CruResource,
    LabeledInput,
    KeyValue,
    LabeledSelect,
  },

  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'edit'
    },
    realMode: {
      type:    String,
      default: 'edit'
    }
  },
  data() {
    return { tip: '' };
  },
  computed: {
    _CREATE() {
      return _CREATE;
    },
    _CLONE() {
      return _CLONE;
    },
    _EDIT() {
      return _EDIT;
    },
    nodeNameList() {
      const steveModels = this.$store.getters['cluster/all']('cluster.liveit100.com.machine');
      const nodeNameList = steveModels?.map(steveModel => steveModel.metadata.name);

      nodeNameList?.splice(nodeNameList?.indexOf(this.value.metadata.name), 1);

      return nodeNameList;
    },
    osList() {
      if (this.$store.state.picloud.osList.length === 0) {
        this.$set(this.value.spec, 'os', '');
      }

      return this.$store.state.picloud.osList?.map((v) => {
        return ({
          label: v.split('/')[v.split('/').length - 1],
          value: v
        });
      });
    },
    cluster() {
      return this.value.metadata.labels['picloud/cluster'];
    }
  },
  methods: {
    done() {
      this.$router.go(-1);
    },
    selectionChanged(e) {
      if (e === 'mode=install' && this.value.status.mode === 'mode=disk') {
        this.tip = this.t('catalog.install.steps.values.machine.installTip');
      }
      if (e === 'provision') {
        this.tip = this.t('catalog.install.steps.values.machine.provisionTip');
      }
    },
    saveScan(cb) {
      if (this.tip) {
        this.$notice({
          type:    'warning',
          message: this.tip
        });
      }
      this.save(cb);
    },
  },
  watch: {
    cluster() {
      this.$store.dispatch('picloud/getOs', this.value.metadata.labels['picloud/cluster']);
    }
  },
  created() {
    // Add fields missing when create or clone
    const spec = {
      ip: '', os: '', install: { device: '' }, cloudconfig: { k3os: {} }
    };

    if (!this.value.spec && this.realMode === _CREATE) {
      this.$set(this.value, 'spec', spec);
      this.$set(this.value.metadata.labels, 'picloud/cluster', '');
    }
    if (!this.value.status) {
      this.$set(this.value, 'status', '');
    }
    for (const key in spec) {
      if (!this.value.spec[key]) {
        this.value.spec[key] = spec[key];
      }
    }
    // query os list
    if (this.realMode !== _CREATE) {
      this.$store.dispatch('picloud/getOs', this.value.metadata.labels['picloud/cluster']);
    }
    if (this.value.metadata.namespace) {
      this.$set(this.value.metadata, 'namespace', 'picloud-system');
    }
    // default setting
    if (!this.value.metadata.annotations || !this.value.metadata.annotations['picloud/role']) {
      this.$set(this.value.metadata.annotations, 'picloud/role', 'agent');
    }
    if (!this.value.metadata.annotations || !this.value.metadata.annotations['picloud/seednode']) {
      this.$set(this.value.metadata.annotations, 'picloud/seednode', 'false');
    }
  }
};
</script>

<template>
  <CruResource
    :cancel-event="true"
    mode="edit"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    @error="e=>errors = e"
    @finish="saveScan"
    @cancel="done"
  >
    <div v-if="realMode===_CLONE">
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.name"
            type="text"
            :required="true"
            :label="t('catalog.install.steps.values.machine.hostName')"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.ip"
            type="text"
            :required="true"
            label="IP"
            :mode="mode"
          />
        </div>
      </div>
      <hr class="mt-20 mb-20" />
      <h4>ANNOTATIONS</h4>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.metadata.annotations['picloud/tmpl']"
            :required="true"
            :label="t('catalog.install.steps.values.machine.templateNode')"
            :placeholder="t('catalog.install.steps.values.SelectPlaceholder')+t('catalog.install.steps.values.machine.templateNode')"
            :tooltip="t('catalog.install.steps.values.machine.templateNodeTooltip')"
            :options="nodeNameList"
          />
        </div>
        <div class="col span-6">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/role']"
            name="picloud/role"
            :label="t('catalog.install.steps.values.machine.nodeRole')"
            :options="[{label:t('catalog.install.steps.values.machine.masterNode'),value:'server'},{label:t('catalog.install.steps.values.machine.secondaryNode'),value:'agent'}]"
            :row="true"
            :required="true"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/seednode']"
            name="picloud/seednode"
            :label="t('catalog.install.steps.values.machine.seedNode')"
            :options="[{label:t('catalog.install.steps.values.machine.yes'),value:'true'},{label:t('catalog.install.steps.values.machine.no'),value:'false'}]"
            :required="true"
            :row="true"
            :tooltip="t('catalog.install.steps.values.machine.firstNode')"
          />
        </div>
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.spec.os"
            :required="true"
            :label="t('catalog.install.steps.values.machine.operatingSystem')"
            :placeholder="t('catalog.install.steps.values.SelectPlaceholder')+t('catalog.install.steps.values.machine.operatingSystem')"
            :options="osList"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.install.device"
            type="text"
            :required="true"
            :label="t('catalog.install.steps.values.machine.systemDisk')"
            :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.machine.systemDisk')"
            mode="edit"
          />
        </div>
      </div>
    </div>
    <div v-else-if="realMode===_CREATE">
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.name"
            type="text"
            :required="true"
            :label="t('catalog.install.steps.values.machine.hostName')"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.ip"
            type="text"
            :required="true"
            label="IP"
            :mode="mode"
          />
        </div>
      </div>
      <hr class="mt-20 mb-20" />
      <h4>LABELS</h4>
      <div class="mb-20 row">
        <div class="col span-6">
          <KeyValue
            v-model="value.metadata.labels"
            :read-allowed="false"
            :key-option-unique="true"
            :mode="mode"
            :protip="false"
          />
        </div>
      </div>
      <hr class="mt-20 mb-20" />
      <h4>ANNOTATIONS</h4>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.metadata.annotations['picloud/tmpl']"
            :required="true"
            :label="t('catalog.install.steps.values.machine.templateNode')"
            :placeholder="t('catalog.install.steps.values.SelectPlaceholder')+t('catalog.install.steps.values.machine.templateNode')"
            :tooltip="t('catalog.install.steps.values.machine.templateNodeTooltip')"
            :options="nodeNameList"
          />
        </div>
        <div class="col span-6">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/role']"
            name="picloud/role"
            :label="t('catalog.install.steps.values.machine.nodeRole')"
            :options="[{label:t('catalog.install.steps.values.machine.masterNode'),value:'server'},{label:t('catalog.install.steps.values.machine.secondaryNode'),value:'agent'}]"
            :row="true"
            :required="true"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/seednode']"
            name="picloud/seednode"
            :label="t('catalog.install.steps.values.machine.seedNode')"
            :options="[{label:t('catalog.install.steps.values.machine.yes'),value:'true'},{label:t('catalog.install.steps.values.machine.no'),value:'false'}]"
            :required="true"
            :row="true"
            :tooltip="t('catalog.install.steps.values.machine.firstNode')"
          />
        </div>
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.spec.os"
            :required="true"
            :label="t('catalog.install.steps.values.machine.operatingSystem')"
            :placeholder="t('catalog.install.steps.values.SelectPlaceholder')+t('catalog.install.steps.values.machine.operatingSystem')"
            :options="osList"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.install.device"
            type="text"
            :required="true"
            :label="t('catalog.install.steps.values.machine.systemDisk')"
            :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.machine.systemDisk')"
            mode="edit"
          />
        </div>
      </div>
      <hr class="mt-20 mb-20" />
      <h4>SPEC</h4>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.arch"
            type="text"
            :required="true"
            label="ARCH"
            mode="edit"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.name"
            type="text"
            :required="true"
            :label="t('catalog.install.steps.values.machine.hostName')"
            mode="view"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.ip"
            type="text"
            :required="true"
            label="IP"
            mode="view"
          />
        </div>
      </div>
      <div class="mb-20">
        <LabeledRadioGroup
          v-model.trim="value.metadata.annotations['picloud/action']"
          :default-value="value.status.mode"
          name="picloud/action"
          :label="t('catalog.install.steps.values.machine.nodeOperation')"
          :options="[{label:t('catalog.install.steps.values.machine.modifyConfiguration'),value:'provision'},{label:t('catalog.install.steps.values.machine.liveMode'),value:'mode=live'},
                     {label:t('catalog.install.steps.values.machine.installationSystem'),value:'mode=install'},{label:t('catalog.install.steps.values.machine.diskBoot'),value:'mode=disk'},
                     {label:t('catalog.install.steps.values.machine.emergencyRepair'),value:'fallback_mode=live'}]"
          :row="true"
          @input="selectionChanged($event)"
        />
      </div>
      <div v-if="value.metadata.annotations['picloud/action'] === 'provision'">
        <div class="mb-20 row">
          <div class="col span-6">
            <LabeledInput
              v-model.trim="value.metadata.annotations['picloud/newname']"
              type="text"
              :required="true"
              :label="t('catalog.install.steps.values.machine.newName')"
              :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.machine.newName')"
              mode="edit"
            />
          </div>
          <div class="col span-6">
            <LabeledSelect
              v-model.trim="value.metadata.annotations['picloud/tmpl']"
              :required="true"
              :label="t('catalog.install.steps.values.machine.templateNode')"
              :placeholder="t('catalog.install.steps.values.SelectPlaceholder')+t('catalog.install.steps.values.machine.templateNode')"
              :tooltip="t('catalog.install.steps.values.machine.templateNodeTooltip')"
              :options="nodeNameList"
            />
          </div>
        </div>
        <div class="mb-20 row">
          <div class="col span-6">
            <LabeledRadioGroup
              v-model.trim="value.metadata.annotations['picloud/role']"
              name="picloud/role"
              :label="t('catalog.install.steps.values.machine.nodeRole')"
              :options="[{label:t('catalog.install.steps.values.machine.masterNode'),value:'server'},{label:t('catalog.install.steps.values.machine.secondaryNode'),value:'agent'}]"
              :row="true"
              :required="true"
            />
          </div>
          <div class="col span-6">
            <LabeledRadioGroup
              v-model.trim="value.metadata.annotations['picloud/seednode']"
              name="picloud/seednode"
              :label="t('catalog.install.steps.values.machine.seedNode')"
              :options="[{label:t('catalog.install.steps.values.machine.yes'),value:'true'},{label:t('catalog.install.steps.values.machine.no'),value:'false'}]"
              :required="true"
              :row="true"
              :tooltip="t('catalog.install.steps.values.machine.firstNode')"
            />
          </div>
        </div>
        <div class="mb-20 row">
          <div class="col span-6">
            <div class="mb-20">
              <LabeledSelect
                v-model.trim="value.spec.os"
                :required="true"
                :label="t('catalog.install.steps.values.machine.operatingSystem')"
                :placeholder="t('catalog.install.steps.values.SelectPlaceholder')+t('catalog.install.steps.values.machine.operatingSystem')"
                :options="osList"
              />
            </div>
          </div>
          <div class="col span-6">
            <div class="mb-20">
              <LabeledInput
                v-model.trim="value.spec.install.device"
                type="text"
                :required="true"
                :label="t('catalog.install.steps.values.machine.systemDisk')"
                :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.machine.systemDisk')"
                mode="edit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </CruResource>
</template>
