<script>
import CreateEditView from '@/mixins/create-edit-view';
import LabeledInput from '@/components/form/LabeledInput';

export default {
  components: { LabeledInput },
  mixins:     [CreateEditView],
  props:      {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    }
  },
  mode: {
    type:    String,
    default: 'edit'
  }
};
</script>

<template>
  <div>
    <h2>{{ t('catalog.install.steps.values.Edit') }}</h2>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.storageSize"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.registry.storageSize')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.registry.storageSize')"
          mode="edit"
        />
      </div>
    </div>
    <h4>Service</h4>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.service.name"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.registry.name')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.registry.name')"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.service.registry.port"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.registry.ImagePort')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.registry.ImagePort')"
          mode="edit"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.service.ui.port"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.registry.UIPort')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.registry.UIPort')"
          mode="edit"
        />
      </div>
    </div>
    <h4>StatefulSet</h4>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.statefulSet.registry.image"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.registry.warehouseImage')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.registry.warehouseImage')"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.statefulSet.ui.image"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.registry.UIImage')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.registry.UIImage')"
          mode="edit"
        />
      </div>
    </div>
  </div>
</template>
