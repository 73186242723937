<script>
import CreateEditView from '@/mixins/create-edit-view';
import LabeledInput from '@/components/form/LabeledInput';
import CruResource from '@/components/CruResource';

export default {
  components: {
    CruResource,
    LabeledInput,
  },

  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'edit'
    },
  },
  methods: {
    done() {
      this.$router.go(-1);
    },
  },
};
</script>

<template>
  <CruResource
    :cancel-event="true"
    mode="edit"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    @error="e=>errors = e"
    @finish="save"
    @cancel="done"
  >
    <div class="mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.spec.picloudurl"
          type="text"
          :required="true"
          label="πCloudURL"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.spec.qvmurl"
          type="text"
          :required="true"
          label="QVMURL"
          mode="edit"
        />
      </div>
    </div>
  </CruResource>
</template>
