<script>
import LabelValue from '@/components/LabelValueRow';
import day from 'dayjs';

export default {
  name:       'DetailMachine',
  components: { LabelValue },
  async fetch() {

  },

  props: {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
  },
  computed: {
    creationTimestamp() {
      return day(this.value?.metadata?.creationTimestamp).format('YYYY-MM-DD HH:mm:ss');
    },
  }
};
</script>

<template>
  <div class="host-detail">
    <h3>{{ t('catalog.install.steps.values.defaultInfo') }}</h3>
    <div class="row mb-20">
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.gateway.name')" :value="value.metadata.name" />
      </div>
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.gateway.address')" :value="value.spec ? value.spec.addr : ''" />
      </div>
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.gateway.user')" :value="value.spec ? value.spec.user : ''" />
      </div>
      <div class="col span-3">
        <LabelValue :name="t('catalog.install.steps.values.gateway.creationTimestamp')" :value="creationTimestamp" />
      </div>
    </div>
    <div class="row mb-20">
      <div class="col span-12">
        <LabelValue :name="t('catalog.install.steps.values.gateway.msg')" :value="value.status ? value.status.msg : ''" />
      </div>
    </div>
  </div>
</template>
