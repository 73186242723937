import { render, staticRenderFns } from "./NamespaceFilter.vue?vue&type=template&id=bd2ff3f2&scoped=true&"
import script from "./NamespaceFilter.vue?vue&type=script&lang=js&"
export * from "./NamespaceFilter.vue?vue&type=script&lang=js&"
import style0 from "./NamespaceFilter.vue?vue&type=style&index=0&id=bd2ff3f2&lang=scss&scoped=true&"
import style1 from "./NamespaceFilter.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../src/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd2ff3f2",
  null
  
)

export default component.exports