import { render, staticRenderFns } from "./ChartReadme.vue?vue&type=template&id=a1caae8a&scoped=true&"
import script from "./ChartReadme.vue?vue&type=script&lang=js&"
export * from "./ChartReadme.vue?vue&type=script&lang=js&"
import style0 from "./ChartReadme.vue?vue&type=style&index=0&id=a1caae8a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../src/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1caae8a",
  null
  
)

export default component.exports