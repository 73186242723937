<script>
import CreateEditView from '@/mixins/create-edit-view';
import LabeledInput from '@/components/form/LabeledInput';
import LabeledRadioGroup from '@/components/form/LabeledRadioGroup';

export default {
  components: { LabeledInput, LabeledRadioGroup },
  mixins:     [CreateEditView],
  props:      {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    }
  },
  mode: {
    type:    String,
    default: 'edit'
  }
};
</script>

<template>
  <div>
    <h2>{{ t('catalog.install.steps.values.Edit') }}</h2>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.configuration.backupStorageLocation.config.s3Url"
          type="text"
          :required="true"
          label="S3URL"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.credentials.secretContents.cloud"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.velero.cloud')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.velero.cloud')"
          mode="edit"
        />
      </div>
    </div>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledRadioGroup
          v-model.trim="value.configuration.defaultVolumesToRestic"
          name="defaultVolumesToRestic"
          :label="t('catalog.install.steps.values.velero.use')"
          :options="[{label:t('catalog.install.steps.values.velero.yes'),value:true},{label:t('catalog.install.steps.values.velero.no'),value:false}]"
          :default-value="value.configuration.defaultVolumesToRestic"
          :row="true"
          :required="true"
        />
      </div>
    </div>
  </div>
</template>
