<template>
  <div>
    <h2>{{ t('catalog.install.steps.values.Edit') }}</h2>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.ingress.hosts"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.grafana.hosts')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.grafana.hosts')"
          mode="edit"
          :tooltip="t('catalog.install.steps.values.grafana.tooltip.hosts')"
        />
      </div>
    </div>
    <h4>DataSource</h4>
    <div class="detail mb-20 row">
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.datasources['datasources.yaml'].datasources[0].name"
          type="text"
          :required="true"
          :label="t('catalog.install.steps.values.grafana.name')"
          :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.grafana.name')"
          mode="edit"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.trim="value.datasources['datasources.yaml'].datasources[0].url"
          type="text"
          :required="true"
          label="URL"
          :placeholder="t('catalog.install.steps.values.placeholder')+'URL'"
          mode="edit"
          :tooltip="t('catalog.install.steps.values.grafana.tooltip.url')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CreateEditView from '@/mixins/create-edit-view';
import LabeledInput from '@/components/form/LabeledInput';

export default {
  components: { LabeledInput },
  mixins:     [CreateEditView],
  props:      {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    }
  },
  mode: {
    type:    String,
    default: 'edit'
  }
};
</script>
