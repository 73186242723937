var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(_vm._s(_vm.t("catalog.install.steps.values.Edit")))]),_vm._v(" "),_c('h4',[_vm._v("NATS")]),_vm._v(" "),_c('div',{staticClass:"detail mb-20 row"},[_c('div',{staticClass:"col span-6"},[_c('LabeledInput',{attrs:{"type":"text","required":true,"label":_vm.t('catalog.install.steps.values.nats.image'),"placeholder":_vm.t('catalog.install.steps.values.placeholder') +
            _vm.t('catalog.install.steps.values.nats.image'),"mode":"edit"},model:{value:(_vm.value.nats.image),callback:function ($$v) {_vm.$set(_vm.value.nats, "image", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.nats.image"}})],1),_vm._v(" "),_c('div',{staticClass:"col span-6"},[_c('LabeledInput',{attrs:{"type":"text","required":true,"label":_vm.t('catalog.install.steps.values.nats.pullPolicy'),"placeholder":_vm.t('catalog.install.steps.values.placeholder') +
            _vm.t('catalog.install.steps.values.nats.pullPolicy'),"mode":"edit"},model:{value:(_vm.value.nats.pullPolicy),callback:function ($$v) {_vm.$set(_vm.value.nats, "pullPolicy", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.nats.pullPolicy"}})],1)]),_vm._v(" "),_c('h5',[_vm._v(_vm._s(_vm.t("catalog.install.steps.values.nats.logging")))]),_vm._v(" "),_c('div',{staticClass:"detail mb-20 row"},[_c('div',{staticClass:"col span-6"},[_c('LabeledRadioGroup',{attrs:{"name":"debug","label":_vm.t('catalog.install.steps.values.nats.debug'),"options":[
          { label: 'true', value: true },
          { label: 'false', value: false } ],"default-value":_vm.value.nats.logging.debug,"row":true,"required":true},model:{value:(_vm.value.nats.logging.debug),callback:function ($$v) {_vm.$set(_vm.value.nats.logging, "debug", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.nats.logging.debug"}})],1),_vm._v(" "),_c('div',{staticClass:"col span-6"},[_c('LabeledRadioGroup',{attrs:{"name":"trace","label":_vm.t('catalog.install.steps.values.nats.trace'),"options":[
          { label: 'true', value: true },
          { label: 'false', value: false } ],"default-value":_vm.value.nats.logging.trace,"row":true,"required":true},model:{value:(_vm.value.nats.logging.trace),callback:function ($$v) {_vm.$set(_vm.value.nats.logging, "trace", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.nats.logging.trace"}})],1)]),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.t("catalog.install.steps.values.nats.cluster")))]),_vm._v(" "),_c('div',{staticClass:"detail mb-20 row"},[_c('div',{staticClass:"col span-6"},[_c('LabeledRadioGroup',{attrs:{"name":"enabled","label":_vm.t('catalog.install.steps.values.nats.enabled'),"options":[
          { label: 'true', value: true },
          { label: 'false', value: false } ],"default-value":_vm.value.cluster.enabled,"row":true,"required":true},model:{value:(_vm.value.cluster.enabled),callback:function ($$v) {_vm.$set(_vm.value.cluster, "enabled", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cluster.enabled"}})],1),_vm._v(" "),_c('div',{staticClass:"col span-6"},[_c('LabeledInput',{attrs:{"type":"text","required":true,"label":_vm.t('catalog.install.steps.values.nats.replicas'),"placeholder":_vm.t('catalog.install.steps.values.placeholder') +
            _vm.t('catalog.install.steps.values.nats.replicas'),"mode":"edit"},model:{value:(_vm.value.cluster.replicas),callback:function ($$v) {_vm.$set(_vm.value.cluster, "replicas", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cluster.replicas"}})],1)]),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.t("catalog.install.steps.values.nats.leafnodes")))]),_vm._v(" "),_c('div',{staticClass:"detail mb-20 row"},[_c('div',{staticClass:"col span-6"},[_c('LabeledRadioGroup',{attrs:{"name":"enabled","label":_vm.t('catalog.install.steps.values.nats.leafnodesEnabled'),"options":[
          { label: 'true', value: true },
          { label: 'false', value: false } ],"default-value":_vm.value.leafnodes.enabled,"row":true,"required":true},model:{value:(_vm.value.leafnodes.enabled),callback:function ($$v) {_vm.$set(_vm.value.leafnodes, "enabled", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.leafnodes.enabled"}})],1)]),_vm._v(" "),_c('div',{staticClass:"detail mb-20 row"},[_c('div',{staticClass:"col span-6"},[_c('LabeledInput',{attrs:{"type":"text","required":true,"label":_vm.t('catalog.install.steps.values.nats.port'),"placeholder":_vm.t('catalog.install.steps.values.placeholder') +
            _vm.t('catalog.install.steps.values.nats.port'),"mode":"edit"},model:{value:(_vm.value.leafnodes.port),callback:function ($$v) {_vm.$set(_vm.value.leafnodes, "port", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.leafnodes.port"}})],1),_vm._v(" "),_c('div',{staticClass:"col span-6"},[_c('LabeledInput',{attrs:{"type":"text","required":true,"label":_vm.t('catalog.install.steps.values.nats.noAdvertise'),"placeholder":_vm.t('catalog.install.steps.values.placeholder') +
            _vm.t('catalog.install.steps.values.nats.noAdvertise'),"mode":"edit"},model:{value:(_vm.value.leafnodes.noAdvertise),callback:function ($$v) {_vm.$set(_vm.value.leafnodes, "noAdvertise", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.leafnodes.noAdvertise"}})],1)]),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.t("catalog.install.steps.values.nats.gateway")))]),_vm._v(" "),_c('div',{staticClass:"detail mb-20 row"},[_c('div',{staticClass:"col span-6"},[_c('LabeledRadioGroup',{attrs:{"name":"enabled","label":_vm.t('catalog.install.steps.values.nats.gatewayEnabled'),"options":[
          { label: 'true', value: true },
          { label: 'false', value: false } ],"default-value":_vm.value.gateway.enabled,"row":true,"required":true},model:{value:(_vm.value.gateway.enabled),callback:function ($$v) {_vm.$set(_vm.value.gateway, "enabled", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.gateway.enabled"}})],1)]),_vm._v(" "),_c('div',{staticClass:"detail mb-20 row"},[_c('div',{staticClass:"col span-6"},[_c('LabeledInput',{attrs:{"type":"text","required":true,"label":_vm.t('catalog.install.steps.values.nats.name'),"placeholder":_vm.t('catalog.install.steps.values.placeholder') +
            _vm.t('catalog.install.steps.values.nats.name'),"mode":"edit"},model:{value:(_vm.value.gateway.name),callback:function ($$v) {_vm.$set(_vm.value.gateway, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.gateway.name"}})],1),_vm._v(" "),_c('div',{staticClass:"col span-6"},[_c('LabeledInput',{attrs:{"type":"text","required":true,"label":_vm.t('catalog.install.steps.values.nats.gatewayPort'),"placeholder":_vm.t('catalog.install.steps.values.placeholder') +
            _vm.t('catalog.install.steps.values.nats.gatewayPort'),"mode":"edit"},model:{value:(_vm.value.gateway.port),callback:function ($$v) {_vm.$set(_vm.value.gateway, "port", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.gateway.port"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }