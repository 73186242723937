<script>
import CreateEditView from '@/mixins/create-edit-view';
import LabeledInput from '@/components/form/LabeledInput';
import CruResource from '@/components/CruResource';
import { _CREATE, _EDIT, _VIEW } from '@/config/query-params';

export default {
  components: {
    CruResource,
    LabeledInput,
  },

  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: _EDIT
    },
    realMode: {
      type:    String,
      default: _EDIT
    }
  },
  data() {
    return { passwd: this.value.spec.passwd ? Buffer.from(this.value.spec.passwd, 'base64').toString() : '' };
  },
  computed: {
    _CREATE() {
      return _CREATE;
    },
    _EDIT() {
      return _EDIT;
    },
    _VIEW() {
      return _VIEW;
    }
  },
  methods:  {
    onChange(e) {
      this.value.spec.passwd = Buffer.from(e).toString('base64');
      this.passwd = Buffer.from(this.value.spec.passwd, 'base64').toString();
    },
    done() {
      this.$router.go(-1);
    }
  },
  created() {
    // Add fields missing when creating new
    const obj = {
      cluster: '', addr: '', user: '', passwd: ''
    };

    if (!this.value.spec) {
      this.$set(this.value, 'spec', obj);
    } else {
      for (const key in obj) {
        if (!this.value.spec[key]) {
          this.value.spec[key] = obj[key];
        }
      }
    }
    this.$set(this.value.metadata, 'namespace', 'picloud-system');
  }

};
</script>

<template>
  <CruResource
    :cancel-event="true"
    :mode="_EDIT"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    @error="e=>errors = e"
    @finish="save"
    @cancel="done"
  >
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.metadata.name"
        type="text"
        :required="true"
        :label="t('catalog.install.steps.values.gateway.name')"
        :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.gateway.name')"
        :mode="(realMode===_EDIT||realMode===_VIEW) ? _VIEW : _EDIT"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.cluster"
        type="text"
        :required="true"
        :label="t('catalog.install.steps.values.gateway.cluster')"
        :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.gateway.cluster')"
        mode="edit"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.addr"
        type="text"
        :required="true"
        :label="t('catalog.install.steps.values.gateway.address')"
        :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.gateway.address')"
        mode="edit"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.user"
        type="text"
        :required="true"
        :label="t('catalog.install.steps.values.gateway.user')"
        :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.gateway.user')"
        mode="edit"
        readonly
        onfocus="this.removeAttribute('readonly')"
        @input="$forceUpdate()"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        :value="passwd"
        type="password"
        :required="true"
        :label="t('catalog.install.steps.values.gateway.password')"
        :placeholder="t('catalog.install.steps.values.placeholder')+t('catalog.install.steps.values.gateway.password')"
        mode="edit"
        readonly
        onfocus="this.removeAttribute('readonly')"
        @input="onChange"
      />
    </div>
  </CruResource>
</template>
