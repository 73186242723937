import Promise from "lodash/_Promise";

export const state = function() {
  return { osList: [] };
};

export const actions = {
  getOs({ commit }, cluster) {
    const url = '/ipxe/os';
    return new Promise((resolve, reject) => {
      return this.$axios.get(url, { params: { cluster }}).then((res) => {
        commit('getOs', res.data);
        resolve(res);
      }).catch((err) => {
        commit('getOs', []);
        reject(err);
      });
    });
  },
};

export const mutations = {
  getOs(state, osList) {
    state.osList = osList;
  }
};
